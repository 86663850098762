module.exports = [
  {
    id: "PoxCF9fYRZk1ltPp6lv71",
    name: "Miriam"
  },
  {
    id: "LilaHF10rtKH23l7jz80i",
    name: "Hannelore"
  },
];
