import React from "react";
import Image from "./components/Image";
import { Abo } from "./components/Abo";
import { NoAbo } from "./components/NoAbo";
import { Button } from "./components/Button";
import { AboValue } from "./components/AboValue";
import { AboLogin } from "./components/AboLogin";
import { AboLogout } from "./components/AboLogout";

export default {
  Image,
  Abo,
  NoAbo,
  Button,
  AboValue,
  AboLogin,
  AboLogout
};
