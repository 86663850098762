import React from "react";
import { useAbo } from "../hooks/useAbo";

export const NoAbo = ({ children }) => {
  const allowed = useAbo();

  if (!allowed) {
    return children;
  } else {
    return null;
  }
};
