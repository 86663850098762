import styled from "styled-components";
import { centered, primaryColor } from "../style/common";
import { Link } from "gatsby";
import React from "react";

const CtaLinkWrapper = styled.div`
  ${centered}
  margin-top: 40px;
  justify-content: flex-start;
`;

const CtaLink = styled(Link)`
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  background-color: ${primaryColor};
  color: #fff;
  border-radius: 24px;
  min-height: 48px;
  ${centered}
  padding: 8px 24px;
  box-sizing: border-box;
`;

export const Button = ({ link, children }) => (
  <CtaLinkWrapper>
    <CtaLink to={link}>{children}</CtaLink>
  </CtaLinkWrapper>
);
