import { useMemo } from "react";
import abo from "../../content/abo";

const aboIndex = abo.reduce((p, c) => {
  p[c.id] = c;
  return p;
}, {});

const inSSR = typeof window === "undefined";

export const useAbo = () => {
  const allowed = useMemo(() => {
    if (inSSR) {
      return false;
    }
    let code = window.localStorage.getItem("code");
    return aboIndex[code];
  }, []);

  return allowed;
};
