import React from "react";
import { useAbo } from "../hooks/useAbo";

export const AboValue = ({ children }) => {
  const abo = useAbo();

  if (abo) {
    return abo[children] || null;
  } else {
    return null;
  }
};
