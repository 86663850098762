import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { centered, primaryColor } from "../style/common";

const inSSR = typeof window === "undefined";

const CtaLink = styled.button`
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  border-color: ${primaryColor};
  background: ${primaryColor};
  color: #fff;
  border-radius: 20px;
  min-height: 40px;
  ${centered}
  padding: 0 12px;
  box-sizing: border-box;
  border: 1px solid ${primaryColor};
  margin-top: 12px;
  cursor: pointer;
  background: ${primaryColor};
  outline: none;
  :disabled {
    cursor: not-allowed;
    background: transparent;
    color: ${primaryColor};
  }
`;

const LoginInput = styled.input`
  border: 1px solid #666;
  height: 40px;
  padding: 0 12px;
  margin: 0;
  line-height: 40px;
  outline: none;
  background: transparent;
  border-radius: 6px;
  width: 100%;
  box-sizing: border-box;
  font-family: Courier, Monaco, monospace;
  font-size: 14px;
  :focus {
    border-color: ${primaryColor};
  }
`;

const LoginForm = styled.form`
  background: #fcfcfc;
  border-radius: 12px;
  border: 1px solid #999;
  padding: 24px;
  max-width: 300px;
  display: block;
`;

const Headline = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
`;

const Error = styled.div`
  font-size: 14px;
  color: #d22;
  line-height: 18px;
  margin-top: 12px;
  * {
    line-height: 18px;
    font-size: 14px;
  }
`;

export const AboLogin = () => {
  const [code, setCode] = useState("");

  const onChange = useCallback(event => {
    setCode(event.target.value);
  });

  const onSubmit = useCallback(event => {
    event.preventDefault();
    if (!code) {
      return;
    }
    window.localStorage.setItem("code", code);
    window.location.href = window.location.href.split("?")[0] + "?login=1";
  });

  const error = useMemo(() => {
    if (inSSR) {
      return false;
    }
    let login = new URLSearchParams(window.location.search).get("login");
    return !!login;
  }, []);

  return (
    <LoginForm onSubmit={onSubmit}>
      <Headline>Anmeldung</Headline>
      <LoginInput
        value={code}
        onChange={onChange}
        placeholder="Login-Code eingeben"
      />
      <CtaLink type="submit" disabled={!code} style={{}}>
        Anmelden
      </CtaLink>
      {error && (
        <Error>
          Kein gültiger Code. Versuche es erneut oder melde dich bei mir (
          <a href="mailto:yogaorange@gmx.de">yogaorange@gmx.de</a>).
        </Error>
      )}
    </LoginForm>
  );
};
