import React from "react";
import { useAbo } from "../hooks/useAbo";

export const Abo = ({ children }) => {
  const abo = useAbo();

  if (abo) {
    return children;
  } else {
    return null;
  }
};
