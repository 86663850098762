import React from "react";
import { MDXProvider } from "@mdx-js/tag";
import styled from "styled-components";
import pageComponents from "./src/page-components";

// components is its own object outside of render so that the references to
// components are stable
const components = {
  wrapper: styled.div``,
  ...pageComponents
};
export const wrapRootElement = ({ element }) => (
  <MDXProvider components={components}>{element}</MDXProvider>
);
