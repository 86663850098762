import React, { useCallback } from "react";
import styled from "styled-components";
import { centered, primaryColor } from "../style/common";

const CtaLink = styled.button`
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  border-color: ${primaryColor};
  border-radius: 20px;
  min-height: 40px;
  ${centered}
  padding: 0 12px;
  box-sizing: border-box;
  border: 1px solid ${primaryColor};
  margin-top: 12px;
  cursor: pointer;
  background: ${primaryColor};
  outline: none;
  background: transparent;
  color: ${primaryColor};
  margin: 60px 0;
`;

export const AboLogout = (callback, deps) => {
  const onSubmit = useCallback(event => {
    event.preventDefault();
    window.localStorage.setItem("code", "");
    window.location.href = window.location.href;
  }, deps);

  return (
    <CtaLink type="button" onClick={onSubmit}>
      Abmelden
    </CtaLink>
  );
};
